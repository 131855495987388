<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <v-container>
    <!-- Create Campaign Modal -->
    <create-campaign-dialog
      v-model="showCreateCampaignModal"
      :value_isForUpdate="isForUpdate"
      :value_ID_for_editing="ID_for_editing"
      :last-id="lastId"
      @setisForUpdateToFalse="setisForUpdateToFalse"
    />
    <!-- Set Scoring Modal -->
    <scoring-dialog v-model="showScoringModal" />

    <!-- Heading Text -->
    <h1
      class="text-h5 font-weight-bold text-black mb-12"
      v-text="'Campaign Manager'"
    ></h1>

    <!-- Actions -->
    <v-row class="mb-6">
      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          class="text-capitalize"
          color="#1F979E"
          :block="$vuetify.breakpoint.xs"
          @click="createCampaign"
        >
          <v-icon
            color="white"
            left
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span class="white--text">Create campaign</span>
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          class="text-capitalize"
          color="#1F979E"
          outlined
          @click.stop="showScoringModal = true"
        >
          Set Scoring
        </v-btn>
      </v-col>

      <!-- for editly -->
      <!-- <v-col
        cols="12"
        sm="auto"
      >
        <v-file-input
          v-model="files"
          counter
          multiple
          accept="image/*"
          label="File input"
          truncate-length="15"
          name="file"
        ></v-file-input>
      </v-col>
      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          elevation="2"
          icon
          @click="upload"
        ></v-btn>
      </v-col> -->

      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          v-if="postNeedApprove.length > 0"
          class="text-capitalize"
          outlined
          @click="showNeedApproval"
        >
          <v-badge
            v-if="needApprovalCount"
            inline
            color="#FC5555"
            :content="postNeedApprove.length"
            class="me-2"
            style="margin-left: -4px"
          >
          </v-badge>
          Need Approval
        </v-btn>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />

      <!-- Search bar -->
      <v-col
        lg="3"
      >
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- Table -->
    <!-- allCampaignsWithAmountOfPosts -->
    <v-data-table
      :headers="tableHeaders"
      :items="allCampaigns"
      item-key="id"
      :search="search"
      :items-per-page="10"
      :loading="false"
      loading-text="Loading... Please wait"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
        'items-per-page-options': [5, 10, 30, 50, 100],
      }"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:item.id="{ item }">
        {{ allCampaigns.findIndex(c => c.id === item.id) + 1 }}
      </template>
      <template v-slot:item.title="{ item }">
        <v-list-item
          @click="toMarketingCalendar(item.id)"
        >
          {{ item.title }}
        </v-list-item>
      </template>
      <!-- kayaknya yg dibawah bisa dihapus -->
      <!-- <template v-slot:item.isDrafted="{ item }">
        <v-list-item
          @click="toMarketingCalendar(item.id)"
        >
          {{ !item.isDrafted ? "Launched" : "Draft" }}
        </v-list-item>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-row
          v-if="$vuetify.breakpoint.mdAndUp"
          no-gutters
        >
          <v-col>
            <v-btn
              class="action-btn me-4"
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="#1F979E"

              @click="toStatistic(item.id)"
            >
              <v-icon>{{ icons.mdiPoll }}</v-icon>
            </v-btn>
            <v-btn
              class="action-btn me-4"
              outlined
              :style="isDark 
                ? { 'border-color': '#5E5669AD' } 
                : { 'border-color': 'lightgray' }"
              color="#1F979E"
              @click="editCampaign(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              class="action-btn"
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="#1F979E"
              @click.stop.prevent="openDialogDeleteCampaign(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-menu
          v-else
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>

          <v-list outlined>
            <v-btn
              icon 
              @click="toStatistic(item.id)"
            >
              <v-icon>{{ icons.mdiPoll }}</v-icon>
            </v-btn>

            <v-btn
              icon
              @click="editCampaign(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>

            <v-btn
              icon
              @click.stop.prevent="openDialogDeleteCampaign(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <!-- Set Scoring Modal -->
    <scoring-dialog v-model="showScoringModal" />

    
    <!-- dialog delete campaign -->
    <v-dialog
      v-model="dialogDeleteCampaign"
      width="600px"
    >
      <v-card>
        <v-card-title>
          <h3 class="font-weight-bold">
            {{ $t('warning') }}
          </h3>
        </v-card-title>
        <v-card-text>
          {{ $t('deleteMessage') }}
        </v-card-text>
        <v-divider class="bottom-action">
        </v-divider>
        <v-card-actions class="justify-end text-end bottom-action">
          <v-btn
            color="#ffeeee"
            class="mr-3"
            @click="dialogDeleteCampaign = false"
          >
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn
            color="success"
            class=""
            @click="deleteCampaign(idCampaign)"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
import useAppConfig from '@/@core/@app-config/useAppConfig'

import {
  mdiClose,
  mdiDotsHorizontalCircle,
  mdiMagnify,
  mdiPlus,
  mdiPoll,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  name: 'CampaignManager',
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      icons: {
        mdiClose,
        mdiDotsHorizontalCircle,
        mdiMagnify,
        mdiPlus,
        mdiPoll,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
      },
      isDark,
      search,
    }
  },
  components: {
    CreateCampaignDialog: () => import('@/views/campaign-manager/components/dialog/CreateCampaignDialog.vue'),
    ScoringDialog: () => import('@/views/campaign-manager/components/dialog/ScoringDialog.vue'),
  },
  data() {
    return {
      tableHeaders: [
        { text: 'Campaign Title', align: 'center', value: 'title' },
        { text: 'Scheduled post', align: 'center', value: 'amountScheduledPosts' },
        { text: 'Sent post', align: 'center', value: 'amountSentPosts' },
        { text: 'Score', align: 'center', value: 'amountScore' },
        { text: 'Action', align: 'center', sortable: false, value: 'actions' },
      ],

      needApprovalCount: 4,
      showCreateCampaignModal: false,
      showScoringModal: false,

      isForUpdate: false,
      ID_for_editing: null,
      postApproval: [],
      sortBy: 'title',
      sortDesc: true,
      dialogDeleteCampaign: false,
      idCampaign: null,

      files: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    allCampaigns() {
      return this.$store.getters['campaign_manager/getAllCampaignsData']
    },
    allCampaignsWithAmountOfPosts() {
      const data = this.$store.getters['campaign_manager/getAllCampaignsData']

      for (const campaign of data) {
        let amountScheduledPostsCounted = 0

        // const amountSentPostsCounted = 0
        if (this.mappedAllScheduledPostsCampaignID) continue
        this.mappedAllScheduledPostsCampaignID.forEach(campaignID => {
          if (campaignID == campaign) amountScheduledPostsCounted++
        })
        campaign.amountScheduledPosts = amountScheduledPostsCounted
      }

      return data
    },
    lastId() {
      return Number(this.allCampaigns[this.allCampaigns.length - 1]?.id) + 1
    },
    campaignApproval() {
      return this.$store.getters['campaign_manager/getCampaignApproval']
    },
    postNeedApprove() {
      const campaignByApprover = this.allCampaigns.filter(el => {
        return el.approvers.includes(this.user.role || this.user.email)
      })
      const postByCampaignRole = []
      this.postApproval.forEach(post => {
        campaignByApprover.forEach(campaign => {
          if (
            campaign.id === post.idCampaign &&
            post.statusApproval === 'Need Approval' &&
            post.approvement.length < 1
          ) {
            const postFormatted = post
            postFormatted.channels = campaign.channelType
            postByCampaignRole.push(postFormatted)
          } else if (
            campaign.id === post.idCampaign &&
            post.statusApproval === 'Need Approval' &&
            post.approvement.length > 0 &&
            post.approvement.find(el => {
              return el.user.email !== this.user.email
            })
          ) {
            const postFormatted = post
            postFormatted.channels = campaign.channelType
            postByCampaignRole.push(postFormatted)
          }
        })
      })

      return postByCampaignRole
    },
    ListIDHasAccessedMarketingCalendarBefore() {
      return this.$store.getters['campaign_manager/getListIDHasAccessedMarketingCalendarBefore']
    },
    mappedAllScheduledPostsCampaignID() {
      return this.$store.getters['marketingCalendar/getSchedules'].map(scheduledPost => scheduledPost.idCampaign)
    },
  },
  async created() {
    // Fetch data for set scoring
    const scoringState = this.$store.getters['campaign_manager/getScoringData']
    const scoringExistInStore = Boolean(!Object.values(scoringState).find(v => !Object.keys(v).length))
    const scoringExistInDb = await this.$store.dispatch('campaign_manager/getScoring', {
      sub_id: this.user.sub_id,
      token: this.user.token,
    })
    if (!scoringExistInStore && !scoringExistInDb) {
      await this.$store.dispatch('campaign_manager/createScoring', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
    }

    // await this.$store.dispatch('campaign_manager/fetchAllCampaignsApproval')
  },
  async mounted() {
    // to fetch all Campaigns made from user
    await this.$store.dispatch('campaign_manager/fetchAllCampaigns')

    await this.$store.dispatch('marketingCalendar/fetchSchedules', { sub_id: this.user.sub_id, token: this.user.token })

    // to set edit campaign disabled before they go into marketing calendar
    // if (!this.hasAccessedMarketingCalendarBefore) {
    //   this.$store.commit('campaign_manager/SET_HAS_ACCESSED_MARKETING_CALENDAR_BEFORE', false)
    // }
    await this.getPostTemplates()
  },
  methods: {
    async editCampaign(ID) {
      //console.log(`editCampaign is invoked`)
      this.isForUpdate = true
      this.ID_for_editing = ID
      const { data } = await this.$store.dispatch('campaign_manager/fetchSpecifiedCampaign', {
        ID_for_fetching: ID,
      })
      //console.log(data)
      this.showCreateCampaignModal = true

      // await this.$store.dispatch('campaign_manager/fetchAllCampaigns')
    },
    createCampaign() {
      this.isForUpdate = false
      this.showCreateCampaignModal = true
    },
    setisForUpdateToFalse() {
      this.isForUpdate = false
    },
    async deleteCampaign(ID) {
      await this.$store.dispatch('campaign_manager/deleteCampaign', {
        id_for_deletion: ID,
        sub_id: this.user.sub_id,
        token: this.user.token,
      })

      await this.$store.dispatch('campaign_manager/fetchAllCampaigns')
      this.dialogDeleteCampaign = false
    },
    async showNeedApproval() {
      const { postNeedApprove } = this
      await this.$router.push({ name: 'campaign-manager-approval', params: { postNeedApprove } })
    },
    toMarketingCalendar(id) {
      this.$store.commit('campaign_manager/SET_ID_HAS_ACCESSED_MARKETING_CALENDAR_BEFORE', id)
      this.$router.push({ path: `/campaign-manager/${id}/marketing-calendar` })
    },
    openSpecifiedCampaignStatisticPage(id) {
      //console.log(`yooo`)
      this.$router.push({ path: `/campaign-manager/${id}/statistic` })
    },
    async getPostTemplates() {
      // to fetch all Post
      await this.$store.dispatch('marketingCalendar/fetchAllPostTemplates', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      this.postApproval = this.$store.getters['marketingCalendar/getPostApproval']
    },
    openDialogDeleteCampaign(id) {
      this.dialogDeleteCampaign = true
      this.idCampaign = id
    },
    async upload() {
      const formData = new FormData()
      formData.append('file', this.files)

      //console.log(this.files)
      //console.log([...formData.entries()])
      //console.log(formData.get('file'))
      await this.$store.dispatch('campaign_manager/createGif', formData)
    },
    validation_checkIfThisIDHasAccessedMarketingCalendar(thisID) {
      const thisIDHasBeenAccessed = this.ListIDHasAccessedMarketingCalendarBefore.find(ID => ID === thisID)

      return thisIDHasBeenAccessed
    },
    toStatistic(id) {
      this.$router.push({ path: `campaign-manager/${id}/statistic/` })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-capitalize {
  text-transform: capitalize;
}

.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>
